import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { navigate } from "@reach/router";
import { theme } from "@classdojo/web";
import { Button } from "@src/components/nessie-web";
import Container from "@src/components/Container";
import { Text } from "@src/components/Text";
import { Box, Flex } from "@src/components/Boxes";
import CommonModal from "@src/components/modals/CommonModal";
import { getBackgrounds } from "@src/components/partials/thankyouteachers/backgroundSelector";
import FallMojo from "@src/assets/images/ecards/fall-mojo.svg";
import SEO from "../components/SEO";
import { logEvent } from "@src/utils/logClient";

const InputLabel = styled.label`
  display: block;
  color: ${theme.colors.taro40};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const inputsCss = `
border-radius: 42px;
border: 2px solid ${theme.colors.taro30};
padding: 20px 15px;
color: ${theme.colors.taro90};
font-size: 18px;
width: 100%;
line-height: 22px;
&::placeholder {
  color: ${theme.colors.taro30};
  font-size: 18px;
  line-height: 22px;
}
&:focus {
  outline: none;
  border-color: ${theme.colors.taro40};
}
`;
const StyledTextInput = styled.input(inputsCss);

const StyledTextArea = styled.textarea(`${inputsCss}
  height: 120px;
`);

const Title = ({ children }) => (
  <Text
    color={theme.colors.taro90}
    fontWeight="700"
    fontSize="50px"
    lineHeight="50px"
    marginBottom="30px"
    marginTop="12px"
  >
    {children}
  </Text>
);

const Description = ({ children }) => (
  <Text color={theme.colors.taro90} fontWeight="700" fontSize="18px" lineHeight="22px">
    {children}
  </Text>
);

const CardFormContainer = ({ children }) => (
  <Flex
    flexGrow={1}
    flexDirection="column"
    borderRadius="30px"
    border="2px solid"
    borderColor={theme.colors.taro30}
    padding="40px"
  >
    {children}
  </Flex>
);

const CardInput = ({ label, placeholder, kind, value, handleChange, ...otherProps }) => {
  return (
    <Box marginBottom="20px">
      <InputLabel>{label}</InputLabel>
      {kind == "input" ? (
        <StyledTextInput
          placeholder={placeholder}
          value={value}
          onChange={(ev) => handleChange(ev.target.value)}
          {...otherProps}
        />
      ) : (
        <StyledTextArea
          placeholder={placeholder}
          value={value}
          onChange={(ev) => handleChange(ev.target.value)}
          {...otherProps}
        />
      )}
    </Box>
  );
};

const ErrorModal = ({ closeModal, errorMessage }) => (
  <CommonModal headerText="" noHeader closeModal={closeModal}>
    <Text color={theme.colors.watermelon50} fontWeight={700} fontSize="20px" textAlign="center">
      {errorMessage} and cannot not be generated
    </Text>
    <Button kind="secondary" onClick={closeModal}>
      Try again
    </Button>
  </CommonModal>
);

const BackgroundSelector = ({ backgroundOption, active, handleClick }) => {
  return (
    <Box
      height="80px"
      width="80px"
      border="2px solid"
      borderRadius="20px"
      marginX={["5px", "10px"]}
      borderColor={active ? theme.colors.taro60 : theme.colors.taro30}
      onClick={() => handleClick()}
      style={{
        backgroundImage: backgroundOption,
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
      }}
    />
  );
};

const goToEcardPage = (code) => {
  navigate(`/ecard/?c=${code}`);
};

const submitCard = (cardData, handleProfanity) => {
  const { selectedBg, teacherNameValue, messageValue, senderNameValue } = cardData;
  const payload = {
    sender: senderNameValue,
    recipient: teacherNameValue,
    message: messageValue,
    template: selectedBg,
  };
  fetch("/api/teachercard", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error && data.error.type == 400) {
        handleProfanity(data.error.detail);
      } else {
        goToEcardPage(data.code);
      }
    });
};

const cardBackgrounds = getBackgrounds("thumb");

const ThankyouTeachers = () => {
  const [selectedBg, setSelectedBg] = useState(0);
  const [teacherNameValue, setTeacherNameValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [senderNameValue, setSenderNameValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);

  const displayError = (errorText) => {
    setErrorMessage(errorText);
    setIsErrorMessageOpen(true);
  };
  const resetCard = () => {
    setSelectedBg(0);
    setTeacherNameValue("");
    setSenderNameValue("");
    setMessageValue("");
  };
  const hideErrorMessage = () => {
    resetCard();
    setIsErrorMessageOpen(false);
  };

  const handleSubmit = () => {
    submitCard({ selectedBg, teacherNameValue, messageValue, senderNameValue }, displayError);
    logEvent({
      eventName: "web.external_page.ecard.submit",
      eventValue: window.location.href,
    });
  };

  const teacherNameIsValid = teacherNameValue.length > 0;
  const messageIsValid = messageValue.length > 0;
  const senderNameIsValid = senderNameValue.length > 0;

  const formIsValid = teacherNameIsValid && messageIsValid && senderNameIsValid;

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.ecard.page_view",
      eventValue: window.location.href,
    });
  }, []);

  return (
    <>
      <SEO
        title="Give thanks!"
        description="Create a beautiful thank you card to share"
        image="https://static.classdojo.com/img/thanks.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          image: "https://static.classdojo.com/img/thanks.png",
        }}
      />
      <Container>
        <Flex padding={["20px 0", "60px 0"]} flexDirection={["column", "row"]}>
          <Flex
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
            paddingRight={[0, 80]}
            alignItems={["center", "flex-start"]}
            textAlign={["center", "left"]}
            marginBottom="20px"
          >
            <img src={FallMojo} alt="" />
            <Title>Give thanks!</Title>
            {/*eslint-disable-next-line jsx-a11y/accessible-emoji*/}
            <Description>
              It’s the perfect time to share some gratitude. <br />
              Customize a thank you card and share your gratitude with the world! 🌎
            </Description>
          </Flex>
          <CardFormContainer>
            <InputLabel>Style</InputLabel>
            <Flex marginBottom="20px">
              {cardBackgrounds.map((bg, i) => {
                return (
                  <BackgroundSelector
                    backgroundOption={bg}
                    active={selectedBg == i}
                    handleClick={() => setSelectedBg(i)}
                    key={i}
                  />
                );
              })}
            </Flex>
            <CardInput
              label="to"
              placeholder="Name"
              kind="input"
              value={teacherNameValue}
              handleChange={setTeacherNameValue}
            />
            <CardInput
              label="What makes you thankful?"
              placeholder="Write your message here"
              kind="textarea"
              maxLength={300}
              value={messageValue}
              handleChange={setMessageValue}
            />
            <CardInput
              label="from"
              placeholder="Your name"
              kind="input"
              value={senderNameValue}
              handleChange={setSenderNameValue}
            />
            <Flex
              marginBottom="14px auto"
              flexDirection={["column", "row"]}
              justifyContent="space-between"
              alignItems="center"
            >
              <Button kind="primary" onClick={() => handleSubmit()} disabled={!formIsValid}>
                Generate Card
              </Button>
            </Flex>
          </CardFormContainer>
        </Flex>
      </Container>
      {isErrorMessageOpen && <ErrorModal closeModal={hideErrorMessage} errorMessage={errorMessage} />}
    </>
  );
};

export default ThankyouTeachers;
